import React from "react";
import { SimpleGrid, Box, useMediaQuery } from "@chakra-ui/react";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";

const SkincareBanner = () => {
  const [isMobile] = useMediaQuery("(max-width: 767px)");

  return (
    <SimpleGrid columns={1}>
      <Box>
        <Link to="/skincare">
          {isMobile ? (
            <StaticImage
              layout="fullWidth"
              objectFit="contain"
              src="../../images/banner/skincare-mobile.png"
              alt="quiz-banner"
              loading="lazy"
            />
          ) : (
            <StaticImage
              layout="fullWidth"
              objectFit="contain"
              src="../../images/banner/skincare-desktop.png"
              alt="quiz-banner"
              loading="lazy"
            />
          )}
        </Link>
      </Box>
    </SimpleGrid>
  );
};

export default SkincareBanner;
