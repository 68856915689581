import React from "react";
import { Box, Text, SimpleGrid, Center, Container, Grid, GridItem } from "@chakra-ui/react";

import { StaticImage } from "gatsby-plugin-image";

const Commerce = () => {
  const BoxStyle = {
    boxShadow: "5px 5px 1px",
    borderStyle: "solid",
    borderColor: "black",
    borderWidth: "2px"
  };

  return (
    <>
      <Box bgSize="100%" bg="linear-gradient(358.93deg, #D80001 0.72%, #E81311 98.89%)">
        <Grid templateColumns="repeat(5, 1fr)" gap={8}>
          <GridItem colSpan={{ base: 5, lg: 3 }}>
            <Container maxW="container.lg" py={10}>
              <Box m="0 auto">
                <Text color="#f7f7f7" mb={6} fontSize="24px" fontWeight="bold">
                  Dapatkan Produk Kami di sini
                </Text>
                <Grid templateColumns="repeat(4, 1fr)" gap={5}>
                  <GridItem colSpan={{ base: 4, lg: 3 }}>
                    <Box>
                      <Text color="#f7f7f7" mb={3} fontSize="18px" fontWeight="bold">
                        OMG Official Store
                      </Text>
                      <SimpleGrid columns={3} spacing={8} mb={6}>
                        <Center
                          bg="white"
                          transform="scale(1)"
                          transition="transform 500ms"
                          _hover={{ transform: "scale(1.05)", transition: "transform 500ms" }}
                          width="100%"
                          height="50px"
                          style={BoxStyle}>
                          <a
                            href={"https://wa.me/6285866399370"}
                            target="_blank"
                            rel="noopener noreferrer">
                            <StaticImage
                              layout="constrained"
                              alt="wa-logo"
                              loading="lazy"
                              src="../../images/whatsapp.png"
                            />
                          </a>
                        </Center>
                        <Center
                          bg="white"
                          transform="scale(1)"
                          transition="transform 500ms"
                          _hover={{ transform: "scale(1.05)", transition: "transform 500ms" }}
                          width="100%"
                          height="50px"
                          style={BoxStyle}>
                          <a
                            href={"https://www.tiktok.com/@itsomgbeauty"}
                            target="_blank"
                            rel="noopener noreferrer">
                            <StaticImage
                              layout="constrained"
                              alt="tiktok-logo"
                              loading="lazy"
                              src="../../images/tiktok.png"
                            />
                          </a>
                        </Center>
                        <Center
                          bg="white"
                          transform="scale(1)"
                          transition="transform 500ms"
                          _hover={{ transform: "scale(1.05)", transition: "transform 500ms" }}
                          width="100%"
                          height="50px"
                          style={BoxStyle}>
                          <a
                            href={"https://shopee.co.id/omg_official"}
                            target="_blank"
                            rel="noopener noreferrer">
                            <StaticImage
                              layout="constrained"
                              alt="shopee-logo"
                              loading="lazy"
                              src="../../images/shopee.png"
                            />
                          </a>
                        </Center>
                        <Center
                          bg="white"
                          transform="scale(1)"
                          transition="transform 500ms"
                          _hover={{ transform: "scale(1.05)", transition: "transform 500ms" }}
                          width="100%"
                          height="50px"
                          style={BoxStyle}>
                          <a
                            href={"https://www.tokopedia.com/omgofficial"}
                            target="_blank"
                            rel="noopener noreferrer">
                            <StaticImage
                              layout="constrained"
                              alt="tokopedia-logo"
                              loading="lazy"
                              src="../../images/tokopedia.png"
                            />
                          </a>
                        </Center>
                        <Center
                          bg="white"
                          transform="scale(1)"
                          transition="transform 500ms"
                          _hover={{ transform: "scale(1.05)", transition: "transform 500ms" }}
                          width="100%"
                          height="50px"
                          style={BoxStyle}>
                          <a
                            href={
                              "https://www.lazada.co.id/omg-oh-my-glam/?from=wangpu&lang=id&langFlag=id&page=1&pageTypeId=2&q=All-Products&sort=priceasc"
                            }
                            target="_blank"
                            rel="noopener noreferrer">
                            <StaticImage
                              layout="constrained"
                              alt="lazada-logo"
                              loading="lazy"
                              src="../../images/lazada.png"
                            />
                          </a>
                        </Center>
                        <Center
                          bg="white"
                          transform="scale(1)"
                          transition="transform 500ms"
                          _hover={{ transform: "scale(1.05)", transition: "transform 500ms" }}
                          width="100%"
                          height="50px"
                          style={BoxStyle}>
                          <a
                            href={
                              "https://www.blibli.com/merchant/omg-official-store/OHM-60023?excludeProductList=false&promoTab=false"
                            }
                            target="_blank"
                            rel="noopener noreferrer">
                            <StaticImage
                              layout="constrained"
                              alt="blibli-logo"
                              loading="lazy"
                              src="../../images/blibli.png"
                            />
                          </a>
                        </Center>
                      </SimpleGrid>
                    </Box>
                  </GridItem>
                  <GridItem pl={{ base: 0, lg: 4, xl: 8 }} colSpan={{ base: 4, lg: 1 }}>
                    <Box>
                      <Text color="#f7f7f7" mb={3} fontSize="18px" fontWeight="bold">
                        Toko Kosmetik Terdekat
                      </Text>
                      <Center
                        bg="white"
                        transform="scale(1)"
                        transition="transform 500ms"
                        _hover={{ transform: "scale(1.05)", transition: "transform 500ms" }}
                        height="50px"
                        style={BoxStyle}>
                        <a
                          href={"https://linktr.ee/tokokosmetikomg"}
                          target="_blank"
                          rel="noopener noreferrer">
                          <StaticImage
                            layout="constrained"
                            alt="toko-logo"
                            loading="lazy"
                            src="../../images/toko.png"
                          />
                        </a>
                      </Center>
                    </Box>
                  </GridItem>
                </Grid>
              </Box>
            </Container>
          </GridItem>
          <GridItem colSpan={{ base: 5, lg: 2 }}>
            <Center>
              <StaticImage
                layout="constrained"
                objectFit="cover"
                alt="makeup-commerce"
                loading="lazy"
                src="../../images/makeup-commerce.png"
              />
            </Center>
          </GridItem>
        </Grid>
      </Box>
    </>
  );
};

export default Commerce;
