import React from "react";
import { Box, Stack, Text, Button, Modal, useMediaQuery } from "@chakra-ui/react";
import ProductModal from "../product/ProductModal";

const ButtonsBpom = ({ data }) => {
  const [modalShow, setModalShow] = React.useState(false);
  const [popupData, setPopupData] = React.useState({});
  const [isMobile] = useMediaQuery("(max-width: 820px)");

  const handleClose = () => {
    setPopupData({});
    setModalShow(false);
  };

  const handleOpen = () => {
    const obj = {
      name: data.name,
      imgSwatch: data.imgProduct,
      tokopedia: data.tokopedia,
      shopee: data.shopee,
      lazada: data.lazada,
      blibli: data.blibli,
      tiktok: data.tiktok,
      toko: data.toko,
      whatsapp: data.whatsapp,
      isSachet: data.isSachet,
      type: data.type,
      no: data.no
    };
    setPopupData(obj);
    setModalShow(true);
  };

  return (
    <Box mt={10}>
      <Text fontWeight="500" fontSize={{ base: "16px", lg: "18px" }} mb={4}>
        No. BPOM: {data?.bpom}
      </Text>
      <Stack direction={["column", "row"]} px={2} spacing="20px">
        {/* uncomment this if virtual makeup is available */}
        {/* <Button
          color="#0f0f0f"
          rounded="3xl"
          border="solid 1px #0f0f0f"
          _focus={{ boxShadow: "none" }}
          w={{ base: "100%", md: "50%" }}
          h="44px"
          bg="transparent">
          Coba Virtual Makeup
        </Button> */}
        <Button
          _focus={{ boxShadow: "none" }}
          _hover={{
            bg: "black"
          }}
          colorScheme="blackAlpha"
          color="white"
          rounded="3xl"
          w="100%"
          h="44px"
          bg="#0f0f0f"
          onClick={handleOpen}>
          Beli
        </Button>
      </Stack>
      <Modal
        isOpen={modalShow}
        size={isMobile ? "sm" : "2xl"}
        onClose={() => handleClose()}
        isCentered={true}>
        <ProductModal PopUpData={popupData} />
      </Modal>
    </Box>
  );
};

export default ButtonsBpom;
