import React from "react";
import { SimpleGrid, Box } from "@chakra-ui/react";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";

const QuizBanner = () => {
  return (
    <>
      <SimpleGrid columns={{ base: 1, md: 2 }}>
        <Box>
          <a href="https://www.instagram.com/ar/3070444003235363/" target="_blank" rel="noreferrer">
            <StaticImage
              layout="fullWidth"
              objectFit="contain"
              src="../../images/homepage/makeup-vto.png"
              alt="quiz-banner"
              loading="lazy"
            />
          </a>
        </Box>
        <Box>
          <Link to={`/beauty-quiz`}>
            <StaticImage
              layout="fullWidth"
              objectFit="contain"
              src="../../images/homepage/makeup-quiz.png"
              alt="quiz-banner"
              loading="lazy"
            />
          </Link>
        </Box>
      </SimpleGrid>
    </>
  );
};

export default QuizBanner;
