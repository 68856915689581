import React, { useState } from "react";
import qore from "../../context/qoreContext";
import {
  Container,
  Text,
  SimpleGrid,
  Box,
  Button,
  useMediaQuery,
  Modal,
  Center,
  Image,
  Stack
} from "@chakra-ui/react";
import Skeleton from "react-loading-skeleton";
import Slider from "react-slick";
import ReviewModal from "../product/ReviewModal";
import styled from "styled-components";
import "../../css/banner.css";
import { AddIcon, StarIcon } from "@chakra-ui/icons";
import ShopeeSrc from "../../images/shopee.png";
import TokopediaSrc from "../../images/tokopedia.png";
import LazadaSrc from "../../images/lazada.png";
import BlibliSrc from "../../images/blibli.png";

const CustomBackground = styled(Box)`
  background: linear-gradient(162.24deg, #e86a67 12.13%, #a62d31 87.87%);
`;

const TitleText = styled(Text)`
  font-family: "PermanentMarker";
  font-size: 48pt;
  padding-top: 4pt;
  text-align: center;

  @media screen and (max-width: 768px) {
    font-size: 36pt;
  }
`;

const MainText = styled(Text)`
  font-family: "AvenirBold";
  font-size: 18pt;
  padding-top: 4pt;
  text-align: center;

  @media screen and (max-width: 768px) {
    font-size: 14pt;
  }
`;

const SliderContainter = styled(Box)`
  overflow-x: hidden;

  li button::before {
    color: white;
  }

  @media screen and (min-width: 768px) and (max-width: 1024px) {
    min-height: 20vh;
    li button::before {
      font-size: 10px;
    }
  }
`;

const CustomSlider = styled(Slider)`
  .slick-dots {
    bottom: -2.5em;
  }
`;

const CustomCard = styled(Box)`
  border: 0;
  border-radius: 25px;
  pointer-events: none;
  align-items: center;
  align-content: center;
  margin: auto;
  max-width: 60vw;

  @media screen and (max-width: 767px) {
    max-width: 68vw;
  }

  @media screen and (min-width: 721px) and (max-width: 1023px) {
    max-width: 64vw;

    .active-card-home {
      transform: scale(1.5);
      transition: transform 500ms;
    }

    .normal-card-home {
      transform: scale(0.75);
      transition: transform 500ms;
    }
  }

  @media screen and (min-width: 1024px) and (max-width: 1119px) {
    min-width: 48vw;
    max-width: 62vw;

    .active-card-home {
      transform: scale(1.5);
      transition: transform 500ms;
    }

    .normal-card-home {
      transform: scale(0.75);
      transition: transform 500ms;
    }
  }

  @media screen and (min-width: 1200px) {
    min-width: 24vw;

    .active-card-home {
      transform: scale(1.5);
      transition: transform 500ms;
    }

    .normal-card-home {
      transform: scale(0.75);
      transition: transform 500ms;
    }
  }
`;

const ContainerCard = styled(Stack)`
  padding: 0 30px;
  @media screen and (max-width: 767px) {
    padding: 0;
  }
`;

const ProductImage = styled(Image)`
  transform: rotate(-5deg);
`;

const BestReview = ({ name }) => {
  const [imageIndex, setImageIndex] = React.useState(0);
  const [isMobile] = useMediaQuery("(max-width: 768px)");
  const skeletonCount = [0, 1, 2, 3];
  const [modalShow, setModalShow] = useState(false);
  const [popupData, setPopupData] = useState({});

  const { data: listProduct, status: statusProduct } = qore.views.activeProducts.useListRow({
    order: "asc",
    variant: name
  });

  const { data: listReview, status: statusReview } = qore.views.allFilteredReview.useListRow({
    order: "asc",
    noLookup: name
  });

  const handleClose = () => {
    setPopupData({});
    setModalShow(false);
  };

  const handleClick = data => {
    var currentDate = new Date();
    const obj = {
      name: "",
      email: "",
      rating: "",
      source: "OMG Website",
      products: data.id,
      title: "",
      review: "",
      date: currentDate
    };

    setPopupData(obj);
    setModalShow(true);
  };

  const settings = {
    centerMode: true,
    infinite: true,
    centerPadding: "120px",
    focusOnSelect: true,
    slidesToShow: listReview.length === 1 ? 1 : listReview.length === 2 ? 2 : listReview.length - 1,
    initialSlide: 1,
    speed: 1500,
    dots: true,
    autoplay: true,
    autoplaySpeed: 10000,
    beforeChange: (current, next) => setImageIndex(next),
    responsive: [
      {
        breakpoint: 360,
        settings: {
          centerMode: false,
          slidesToShow: 1,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 640,
        settings: {
          centerMode: false,
          slidesToShow: 1,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 720,
        settings: {
          centerMode: false,
          slidesToShow: 1,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true
        }
      },
      {
        breakpoint: 1401,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true
        }
      }
    ]
  };

  const Stars = stars => {
    var indents = [];
    for (var i = 0; i < stars.star; i++) {
      indents.push(<StarIcon w={4} h={4} color="yellow.500" />);
    }
    return indents;
  };

  return (
    <>
      <CustomBackground>
        <Container maxW={{ lg: "container.xl", xl: "container.xl" }} py={10}>
          <Box textAlign="center">
            <TitleText textAlign="center" color="white" mb={8}>
              Best Review
            </TitleText>
            <Button
              size="md"
              width={"180px"}
              borderRadius="20px"
              bg="#262626"
              colorScheme="blackAlpha"
              color="white"
              verticalAlign={"center"}
              leftIcon={<AddIcon />}
              onClick={() => handleClick(listProduct[0])}
              _focus={{ boxShadow: "none" }}
              _hover={{
                bg: "black"
              }}>
              Tambah Review
            </Button>
          </Box>
        </Container>
        <SliderContainter h={{ base: "64vh", sm: "62vh", md: "56vh", lg: "56vh" }}>
          {statusReview === "success" && listReview.length > 0 && statusProduct === "success" ? (
            <CustomSlider {...settings}>
              {listReview.map((reviewData, idx) => (
                <ContainerCard key={idx}>
                  <CustomCard
                    className={idx === imageIndex ? "active-card-home" : "normal-card-home"}
                    opacity={idx === imageIndex ? "1" : "0.5"}
                    bg="white">
                    <SimpleGrid mx="-3.5em" pt="-3em" pb="1em">
                      <Center>
                        <ProductImage
                          src={listProduct[0].imgProduct}
                          alt={listProduct[0].name}
                          w={{ base: "120px", md: "140px", xl: "180px" }}
                          h={{ base: "180px", md: "220px", xl: "260px" }}
                          objectFit="contain"
                          loading="lazy"
                          opacity={idx === imageIndex ? "1" : "0"}
                        />
                        <Box w={{ base: "75%", md: "90%", xl: "100%" }} my={4} mx={2}>
                          <Box mt={3}>
                            <Stars star={reviewData.rating} />
                          </Box>
                          <Stack mt={4} mb={3} w={idx === imageIndex ? "90%" : "100%"}>
                            <Text fontWeight="bold">{reviewData.name}</Text>
                            <Text textColor="#66686C">{reviewData.productsLookup}</Text>
                            <Text>{reviewData.review}</Text>
                            <Text>
                              From&nbsp;
                              <span>
                                {reviewData.source === "Shopee" ? (
                                  <a
                                    href="https://shopee.co.id/omg_official"
                                    target="_blank"
                                    rel="noreferrer">
                                    <Image
                                      style={{ display: "block" }}
                                      src={ShopeeSrc}
                                      alt="shopee"
                                    />
                                  </a>
                                ) : reviewData.source === "Tokopedia" ? (
                                  <a
                                    href="https://www.tokopedia.com/omgofficial"
                                    target="_blank"
                                    rel="noreferrer">
                                    <Image
                                      style={{ display: "block" }}
                                      src={TokopediaSrc}
                                      alt="tokopedia"
                                    />
                                  </a>
                                ) : reviewData.source === "Lazada" ? (
                                  <a
                                    href="https://www.lazada.co.id/omg-oh-my-glam/?from=wangpu&lang=id&langFlag=id&page=1&pageTypeId=2&q=All-Products&sort=priceasc"
                                    target="_blank"
                                    rel="noreferrer">
                                    <Image
                                      style={{ display: "block" }}
                                      src={LazadaSrc}
                                      alt="lazada"
                                    />
                                  </a>
                                ) : reviewData.source === "Blibli" ? (
                                  <a
                                    href="https://www.blibli.com/merchant/omg-official-store/OHM-60023?excludeProductList=false&promoTab=false"
                                    target="_blank"
                                    rel="noreferrer">
                                    <Image
                                      style={{ display: "block" }}
                                      src={BlibliSrc}
                                      alt="blibli"
                                    />
                                  </a>
                                ) : reviewData.source === "OMG Website" ? (
                                  "OMG Website"
                                ) : (
                                  "Unknown"
                                )}
                              </span>
                            </Text>
                          </Stack>
                        </Box>
                        <ProductImage
                          src={listProduct[0].imgProduct}
                          alt={listProduct[0].name}
                          w={{ base: "120px", md: "140px", xl: "180px" }}
                          h={{ base: "180px", md: "220px", xl: "260px" }}
                          objectFit="contain"
                          loading="lazy"
                          opacity={idx === imageIndex ? "1" : "0"}
                        />
                      </Center>
                    </SimpleGrid>
                  </CustomCard>
                </ContainerCard>
              ))}
            </CustomSlider>
          ) : statusReview === "success" && listReview.length === 0 ? (
            <ContainerCard>
              <Center>
                <MainText>Belum ada ulasan untuk produk ini</MainText>
              </Center>
            </ContainerCard>
          ) : (
            <SimpleGrid columns={skeletonCount.length} direction="row">
              {skeletonCount.map(skeleton => (
                <Box p={{ base: 0, lg: "10px 0" }} key={skeleton}>
                  <CustomCard
                    px={{ base: 0, lg: 5 }}
                    className={skeleton === imageIndex ? "active-card-home" : "normal-card-home"}>
                    <Skeleton height={"32vh"} />
                  </CustomCard>
                </Box>
              ))}
            </SimpleGrid>
          )}
        </SliderContainter>
      </CustomBackground>
      <Modal
        isOpen={modalShow}
        size={isMobile ? "sm" : "2xl"}
        onClose={() => handleClose()}
        isCentered={true}>
        <ReviewModal handleClose={handleClose} PopUpData={popupData} />
      </Modal>
    </>
  );
};

export default BestReview;
