function deslugify(slug) {
  if (slug) {
    let separatedWord = [];
    let tempSeparatedWord = {};
    let words = slug.split("-").join(",").split("/").join(",").split(",");

    for (let i = 0; i < words.length; i++) {
      let word = words[i];
      words[i] = word.charAt(0).toUpperCase() + word.slice(1);
    }
    if (typeof parseInt(words[words.length - 1]) === "number") {
      let number = words[words.length - 1];
      words.pop();
      tempSeparatedWord.name = words.join(" ");
      tempSeparatedWord.no = number;
      separatedWord.push(tempSeparatedWord);
    } else {
      tempSeparatedWord.name = words.join(" ");
      tempSeparatedWord.no = null;
      separatedWord.push(tempSeparatedWord);
    }

    return separatedWord;
  }
}

export default deslugify;
