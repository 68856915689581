import React from "react";
import {
  Box,
  SimpleGrid,
  Center,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Image,
  Text
} from "@chakra-ui/react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import GenericClaim1 from "../../images/claim-1.png";
import GenericClaim2 from "../../images/claim-2.png";
import styled from "styled-components";
import DOMPurify from "dompurify";

const ArticleContainer = styled.div`
  font-family: "Avenir";
  font-size: "14px";

  p {
    margin-bottom: 14px;
  }
`;

const defaultFAQ = [
  {
    title: "Apakah produk ini membuat bibir kering?",
    contents:
      "Produk ini mengandung Vitamin E dan Jojoba Oil yang dapat menjaga kelembapan bibir. Namun, sebaiknya gunakan lip balm sebelum menggunakan produk ini agar bibir terlihat lebih sehat dan lembap"
  },
  {
    title: "Apakah produk ini membuat bibir menjadi hitam?",
    contents:
      "Bibir yang hitam salah satunya disebabkan karena kurang bersih dalam menghapus lip product yang telah digunakan. Usahakan selalu menghapus lip product menggunakan Make Up Remover/Micellar Water setelah selesai digunakan"
  },
  {
    title: "Apa yang membedakan produk ini dengan kompetitornya?",
    contents:
      "Produk ini memiliki formula yang intense, tidak lengket, tahan lama, dan transferproof/maskproof (setelah 5 menit pemakaian) sehingga nyaman untuk digunakan sehari-hari. Selain itu, produk ini juga memiliki kemasan tester yang menarik dan dapat langsung dipajang dengan mudah oleh tuan toko"
  },
  {
    title: "Apakah produk ini aman untuk ibu hamil dan menyusui?",
    contents: "Produk ini aman untuk Ibu hamil dan menyusui"
  },
  {
    title: "Apakah produk ini halal?",
    contents: "Ya, produk ini sudah mendapatkan sertifikasi halal dari MUI"
  },
  {
    title: "Apakah produk ini terdaftar di BPOM?",
    contents:
      "Ya! Produk ini sudah terdaftar dan aman digunakan. Silahkan cek nomer BPOM di masing-masing deskripsi produk"
  }
];

const FaqCard = ({ title, contents }) => (
  <Box mb={4}>
    <Text mb={2} fontWeight="semibold" textAlign="justify">
      {title}
    </Text>
    <Text textAlign="justify">{contents}</Text>
  </Box>
);

const ClaimInfo = ({
  productDescription,
  tipsPenggunaan,
  productInformation,
  ingredients,
  faq,
  claimImage1,
  claimImage2,
  claimImage3,
  claimText1,
  claimText2,
  claimText3
}) => {
  const createMarkup = html => {
    return {
      __html: DOMPurify.sanitize(html)
    };
  };

  return (
    <Box mt={{ base: 8, lg: 10 }}>
      <Center>
        <SimpleGrid columns={3} spacing={6}>
          <Box textAlign="center">
            <Center>
              <Image
                layout="fixed"
                objectFit="contain"
                src={claimImage1 ? claimImage1 : GenericClaim1}
                alt="claim-3"
                loading="lazy"
              />
            </Center>
            {claimText1 && (
              <Text
                textAlign="center"
                fontWeight="bold"
                fontSize="14px"
                width="100%"
                marginTop="14px">
                {claimText1}
              </Text>
            )}
          </Box>
          <Box textAlign="center">
            <Center>
              <Image
                layout="fixed"
                objectFit="contain"
                src={claimImage2 ? claimImage2 : GenericClaim2}
                alt="claim-3"
                loading="lazy"
              />
            </Center>
            {claimText2 && (
              <Text
                textAlign="center"
                fontWeight="bold"
                fontSize="14px"
                width="100%"
                marginTop="14px">
                {claimText2}
              </Text>
            )}
          </Box>
          <Box textAlign="center">
            <Center>
              <Image
                layout="fixed"
                objectFit="contain"
                src={claimImage3 ? claimImage3 : GenericClaim2}
                alt="claim-3"
                loading="lazy"
              />
            </Center>
            {claimText3 && (
              <Text
                textAlign="center"
                fontWeight="bold"
                fontSize="14px"
                width="100%"
                marginTop="14px">
                {claimText3}
              </Text>
            )}
          </Box>
        </SimpleGrid>
      </Center>
      <Box>
        <Text textAlign="left" fontSize="16px" mt={8}>
          {productDescription}
        </Text>
      </Box>
      <Accordion mt={{ base: 4, md: 10 }} allowMultiple>
        <AccordionItem>
          <h2>
            <AccordionButton _focus={{ boxShadow: "none" }}>
              <Box fontWeight="semibold" fontSize="18px" flex="1" textAlign="left">
                Tips Penggunaan
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            {tipsPenggunaan ? (
              <ArticleContainer dangerouslySetInnerHTML={createMarkup(tipsPenggunaan)} />
            ) : (
              <>
                <Text mb={4} textAlign="justify">
                  Ingin tahu lebih banyak mengenai cara pemakaian OMG Lip Cream?
                </Text>
                <Link
                  to="/beauty-tips/article/6-cara-gampang-buat-bibir-ombre-ala-korea"
                  target="_blank">
                  <Box width="320px">
                    <StaticImage
                      objectFit="contain"
                      src="../../images/ombretips.jpg"
                      alt="ombre-tips"
                      loading="lazy"
                      width="100%"
                    />
                    <Text textDecoration="underline" textAlign="center" mt={2}>
                      Rekomendasi Ombre Looks Kami
                    </Text>
                  </Box>
                </Link>
              </>
            )}
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem>
          <h2>
            <AccordionButton _focus={{ boxShadow: "none" }}>
              <Box fontWeight="semibold" fontSize="18px" flex="1" textAlign="left">
                Product Information
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            {productInformation ? (
              <ArticleContainer dangerouslySetInnerHTML={createMarkup(productInformation)} />
            ) : (
              <>
                <Text mb={3} textAlign="justify">
                  Netto: 3.7 gr
                </Text>
                <Text mb={3} textAlign="justify">
                  OMG Oh My Glam Matte Kiss Lip Cream merupakan lip cream dengan warna yang intense
                  dan cocok untukmu,
                  <Text as="span" fontWeight="semibold">
                    SEKALI SWIPE-TAHAN SEHARIAN.
                  </Text>
                </Text>
                <Text mb={3} textAlign="justify">
                  Hasil akhir matte, namun tidak cracky karena lapisan formulanya fleksibel setelah
                  set di bibir. Lip cream menempel dengan baik pada bibir sehingga tidak mudah
                  hilang. Menjaga kelembapan bibir dan melindungi bibir dari radikal bebas
                  (antioksidan).
                </Text>
                <Text mb={3} textAlign="justify">
                  Teruji aman digunakan di laboratorium dan terdaftar di BPOM.
                </Text>
              </>
            )}
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem>
          <h2>
            <AccordionButton _focus={{ boxShadow: "none" }}>
              <Box fontWeight="semibold" fontSize="18px" flex="1" textAlign="left">
                Ingredients
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            {ingredients ? (
              <ArticleContainer dangerouslySetInnerHTML={createMarkup(ingredients)} />
            ) : (
              <>
                <Text mb={2} textAlign="justify">
                  Isododecane, Aluminum Starch Octenylsuccinate, Trisiloxane, Diisostearyl Malate,
                  Trimethylsiloxysilicate, Silica Dimethyl Silylate, Disteardimonium Hectorite,
                  Polyglyceryl-6 Polyhydroxystearate, Propylene Carbonate, Polyhydroxystearic Acid,
                  Cyclopentasiloxane, Phenoxyethanol, Polyglyceryl-6 Polyricinoleate,
                  Ethylhexylglycerin, Ethylhexyl Palmitate, Caprylyl Methicone, Dimethicone/Vinyl
                  Dimethicone Crosspolymer, Fragrance, Simmondsia Chinensis (Jojoba) Seed Oil,
                  Tocopheryl Acetate, Tocopherol, Ascorbyl Palmitate.
                </Text>
                <Text>
                  May Contain:
                  <br />
                  CI 15850:1, CI 77491, CI 77891, CI 77492, CI 15850, CI 77499.
                </Text>
              </>
            )}
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem>
          <h2>
            <AccordionButton _focus={{ boxShadow: "none" }}>
              <Box fontWeight="semibold" fontSize="18px" flex="1" textAlign="left">
                FAQ
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            {faq ? (
              <ArticleContainer dangerouslySetInnerHTML={createMarkup(faq)} />
            ) : (
              defaultFAQ?.map((faq, idx) => (
                <React.Fragment key={idx}>
                  <FaqCard title={faq?.title} contents={faq?.contents} />
                </React.Fragment>
              ))
            )}
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </Box>
  );
};

export default ClaimInfo;
