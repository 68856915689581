import React from "react";
import Layout from "../../components/layout";
import Seo from "../../components/seo";
import DetailComponent from "../../components/make-up/Detail";
import Commerce from "../../components/make-up/Commerce";
import QuizBanner from "../../components/make-up/QuizBanner";
import BestReview from "../../components/make-up/BestReview";
import BeautyTutorial from "../../components/make-up/BeautyTutorial";
import SkincareBanner from "../../components/make-up/SkincareBanner";
import deslugify from "../../components/deslugify";

const detail = props => {
  var data = deslugify(props.params["*"]);
  return (
    <Layout>
      <Seo title={`Oh My Glam ${data ? data[0].name : null}`} />
      {data ? <DetailComponent lipNumber={data[0].no} parentName={data[0].name} /> : null}
      <Commerce />
      {data ? <QuizBanner lipNumber={data[0].no} parentName={data[0].name} /> : null}
      {data ? <BestReview name={data[0].no} parentName={data[0].name} /> : null}
      <BeautyTutorial />
      <SkincareBanner />
    </Layout>
  );
};

export default detail;
