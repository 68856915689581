import React from "react";
import qore from "../../context/qoreContext";
import {
  Container,
  SimpleGrid,
  Box,
  useMediaQuery,
  Image,
  Spacer,
  Flex,
  Skeleton,
  Text,
  Button,
  SkeletonCircle
} from "@chakra-ui/react";
import ImageGallery from "react-image-gallery";

import "../../css/image-gallery.css";
import ButtonsBpom from "./ButtonsBpom";
import ClaimInfo from "./ClaimInfo";

const Detail = ({ lipNumber, parentName }) => {
  const [filterProps] = React.useState({
    parentName: parentName,
    no: lipNumber
  });

  const { data: detailProduct, status: statusDetailProduct } = qore
    .view("makeupProducts")
    .useListRow({
      ...filterProps
    });

  const [productImages, setProductImages] = React.useState([]);
  const [productShow, setProductShow] = React.useState(null);
  const [activeIndex, setActiveIndex] = React.useState(null);
  const [productFilter, setProductFilter] = React.useState("");
  const [isMobile] = useMediaQuery("(max-width: 767px)");
  const shadingCount = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

  React.useEffect(() => {
    if (detailProduct.length > 0) {
      setProductFilter(detailProduct[0]?.parentName);
    }
  }, [detailProduct]);

  const { data: listProduct, status: statusListProduct } = qore.view("makeupProducts").useListRow({
    "$by.no": "asc",
    parentName: productFilter
  });

  React.useEffect(() => {
    if (listProduct.length > 0) {
      const index = listProduct.findIndex(element => element.no == lipNumber);
      const productDefault = listProduct[index];
      setProductShow(productDefault);
      setActiveIndex(index);

      if (productDefault) {
        const images = [
          {
            original: productDefault.imgLip,
            thumbnail: productDefault.imgLip
          },
          {
            original: productDefault.imgHand,
            thumbnail: productDefault.imgHand
          },
          {
            original: productDefault.imgModel,
            thumbnail: productDefault.imgModel
          }
        ];

        setProductImages(images);
      }
    }
  }, [listProduct, lipNumber]);

  React.useEffect(() => {
    if (productShow) {
      const images = [
        {
          original: productShow.imgLip,
          thumbnail: productShow.imgLip
        },
        {
          original: productShow.imgHand,
          thumbnail: productShow.imgHand
        },
        {
          original: productShow.imgModel,
          thumbnail: productShow.imgModel
        }
      ];

      setProductImages(images);
    }
  }, [productShow]);

  const handleClick = index => {
    setProductShow(listProduct[index]);
    setActiveIndex(index);
  };

  return (
    <Container maxW={{ lg: "container.md", xl: "container.xl" }} py={{ base: 5, lg: 20 }}>
      <SimpleGrid columns={{ base: 1, md: 2 }} spacing={{ md: 4, lg: 10 }}>
        <Box>
          <Flex position={isMobile ? "auto" : "sticky"} top={isMobile ? "auto" : "40"}>
            {!isMobile && <Spacer />}
            {statusDetailProduct === "success" ? (
              <>
                <ImageGallery
                  showFullscreenButton={false}
                  showPlayButton={false}
                  showNav={isMobile ? true : false}
                  items={productImages}
                  showThumbnails={isMobile ? false : true}
                  showBullets={isMobile ? true : false}
                />
                <Image
                  src={productShow?.imgProduct}
                  maxHeight={{ base: "330px", md: "300px", lg: "300px", xl: "400px" }}
                  maxWidth={{ base: "330px", md: "300px", lg: "300px", xl: "400px" }}
                  objectFit="contain"
                  alt="OMG Make Up"
                  mt={0}
                />
              </>
            ) : (
              <>
                <Skeleton
                  height={{ base: "330px", md: "300px", lg: "300px", xl: "400px" }}
                  width={{ base: "330px", md: "300px", lg: "300px", xl: "400px" }}
                />
                <Skeleton
                  ml={1}
                  height={{ base: "330px", md: "300px", lg: "300px", xl: "400px" }}
                  width={{ base: "120px", md: "120px", lg: "160px" }}
                />
              </>
            )}
            {!isMobile && <Spacer />}
          </Flex>
        </Box>
        <Box>
          <Text fontWeight="800" fontSize="28px" mt={isMobile ? 4 : 0} mb={2}>
            Oh My Glam {parentName}
          </Text>
          <SimpleGrid columns={6} spacing={1}>
            {statusListProduct === "success"
              ? listProduct.map((product, idx) => (
                  <Button
                    key={product.id}
                    isActive={activeIndex === idx}
                    _active={{
                      boxShadow: "rgb(255 255 255) 0px 0px 0px 0.2rem inset",
                      bgColor: product.hexColor,
                      border: "1px solid #0f0f0f"
                    }}
                    _focus={{
                      boxShadow: "none"
                    }}
                    _hover={{
                      bgColor: product.hexColor
                    }}
                    colorScheme="blackAlpha"
                    onClick={() => handleClick(idx)}
                    mt={6}
                    h={{ base: "52px", xl: "80px" }}
                    w={{ base: "52px", xl: "80px" }}
                    borderRadius="50%"
                    bgColor={product.hexColor}>
                    <Text fontSize={{ base: "12px", lg: "14px" }}>
                      {product.no}
                      <br />
                      {product.name}
                    </Text>
                  </Button>
                ))
              : shadingCount.map(shading => (
                  <SkeletonCircle key={shading} mt={6} size={{ base: "52px", xl: "80px" }} />
                ))}
          </SimpleGrid>
          <ButtonsBpom data={productShow} />
          {statusListProduct || statusDetailProduct === "success" ? (
            <ClaimInfo
              productDescription={productShow?.productDescription}
              tipsPenggunaan={productShow?.tipsPenggunaan}
              productInformation={productShow?.productInformation}
              ingredients={productShow?.ingredients}
              faq={productShow?.faq}
              claimImage1={productShow?.claimImage1}
              claimImage2={productShow?.claimImage2}
              claimImage3={productShow?.claimImage3}
              claimText1={productShow?.claimText1}
              claimText2={productShow?.claimText2}
              claimText3={productShow?.claimText3}
            />
          ) : (
            <>
              <Skeleton
                height={{ base: "330px", md: "300px", lg: "300px", xl: "400px" }}
                width={{ base: "990px", md: "900px", lg: "900px", xl: "1200px" }}
              />
              <Skeleton
                height={{ base: "330px", md: "300px", lg: "300px", xl: "400px" }}
                width={{ base: "990px", md: "900px", lg: "900px", xl: "1200px" }}
              />
            </>
          )}
        </Box>
      </SimpleGrid>
    </Container>
  );
};

export default Detail;
