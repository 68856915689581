import React from "react";
import { Box, Text, Grid, GridItem, AspectRatio } from "@chakra-ui/react";
import Skeleton from "react-loading-skeleton";
import Slider from "react-slick";
import qore from "../../context/qoreContext";
import LiteYouTubeEmbed from "react-lite-youtube-embed";
import "react-lite-youtube-embed/dist/LiteYouTubeEmbed.css";
import "../../css/banner.css";

const BeautyTutorial = () => {
  const { data: tutorials, status } = qore.view("activeTutorial").useListRow({});
  const skeletonCount = [1, 2];

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 2.5,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 1.75
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1.85
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1.075
        }
      },
      {
        breakpoint: 375,
        settings: {
          slidesToShow: 1.04
        }
      },
      {
        breakpoint: 320,
        settings: {
          slidesToShow: 1
        }
      }
    ]
  };

  return (
    <Box
      overflow="hidden"
      px={{ base: 4, lg: 0 }}
      py={{ base: 20, lg: "115px" }}
      ml={{ md: "auto", lg: "5%", xl: "10%" }}>
      <Grid templateColumns="repeat(4, 1fr)" gap={10}>
        <GridItem colSpan={{ base: 4, lg: 1 }}>
          <Text fontWeight="bold" fontSize="24px">
            Beauty Tutorial
          </Text>
          <Text mt={4} fontSize="18px" maxW="280px">
            Yuk intip review, tutorial dan tips-tips menarik dari influencer kesayangan kamu!
          </Text>
        </GridItem>
        <GridItem colSpan={{ base: 4, lg: 3 }}>
          <Box>
            {status === "success" ? (
              <Slider {...settings}>
                {tutorials.length &&
                  tutorials.map((tutorial, idx) => (
                    <AspectRatio
                      key={idx}
                      maxW={{ base: "320px", md: "360px", xl: "480px" }}
                      ratio={16 / 9}>
                      <LiteYouTubeEmbed id={tutorial?.link} title={tutorial?.name} />
                    </AspectRatio>
                  ))}
              </Slider>
            ) : (
              <Grid
                templateColumns={{ base: "repeat(3, 1fr)", md: "repeat(4, 1fr)" }}
                gap={6}
                overflow="hidden">
                {skeletonCount.map(skeleton => (
                  <GridItem
                    key={skeleton}
                    h={{ base: "140px", md: "200px", lg: "270px" }}
                    w={{ base: "140px", md: "200px", lg: "480px" }}
                    display={skeleton === 4 ? { base: "none", md: "block" } : { base: "block" }}>
                    <Skeleton height="100%" width="100%" />
                  </GridItem>
                ))}
              </Grid>
            )}
          </Box>
        </GridItem>
      </Grid>
    </Box>
  );
};

export default BeautyTutorial;
